<template>
  <div class="pa-3 d-flex flex-column">
    <v-dialog v-model="dialogAguarde" persistent width="13vw">
      <div
        class="d-flex justify-center align-center"
        style="height: 60px;background-color: azure;"
      >
        <span>Buscando dados...</span>
        <v-progress-circular
          :width="3"
          color="green"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>
    <div>
      <v-row class="d-flex align-center">
        <v-col cols="1" class="ma-2">
          <v-select
            class="rounded-lg"
            v-model="anoEscolhido"
            :items="anos"
            label="Escolha o ano"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2" class="ma-2">
          <v-autocomplete
            :disabled="!anoEscolhido"
            class="rounded-lg"
            v-model="trimestre"
            :items="[
              { text: '1 trimestre', value: '0101-0331' },
              { text: '2 trimestre', value: '0401-0630' },
              { text: '3 trimestre', value: '0701-0930' },
              { text: '4 trimestre', value: '1001-1231' },
            ]"
            item-text="text"
            item-value="value"
            label="Escolha o Trimestre"
            @change="buscaDados"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <div
        class="d-flex justify-space-around align-center"
        v-if="dadosAgenda.length > 0"
      >
        <v-badge color="black darken-2" :content="totalGeral">
          <div class="d-flex flex-column align-center">
            <span class="font-weight-bold black--text text--darken-2"
              >Geral</span
            >
          </div>
        </v-badge>
        <v-badge color="blue darken-2" :content="totalAgendados">
          <div class="d-flex flex-column align-center">
            <span class="font-weight-bold blue--text text--darken-2"
              >Agendado</span
            >
            <span class="mt-2 font-weight-bold blue--text text--darken-2"
              >{{ ((totalAgendados * 100) / totalGeral).toFixed(2) }} %</span
            >
          </div>
        </v-badge>
        <v-badge color="green darken-2" :content="totalCompareceu">
          <div class="d-flex flex-column align-center">
            <span class="font-weight-bold green--text text--darken-2"
              >Compareceu</span
            >
            <span class="mt-2 font-weight-bold green--text text--darken-2"
              >{{ ((totalCompareceu * 100) / totalGeral).toFixed(2) }} %</span
            >
          </div>
        </v-badge>
        <v-badge color="red darken-2" :content="totalNCompareceu">
          <div class="d-flex flex-column align-center">
            <span class="font-weight-bold red--text text--darken-2"
              >Não compareceu</span
            >
            <span class="mt-2 font-weight-bold red--text text--darken-2"
              >{{ ((totalNCompareceu * 100) / totalGeral).toFixed(2) }} %</span
            >
          </div>
        </v-badge>
        <v-badge color="grey darken-2" :content="totalBloqueados">
          <div class="d-flex flex-column align-center">
            <span class="font-weight-bold grey--text text--darken-2"
              >Bloqueado</span
            >
            <span class="mt-2 font-weight-bold grey--text text--darken-2"
              >{{ ((totalBloqueados * 100) / totalGeral).toFixed(2) }} %</span
            >
          </div>
        </v-badge>
      </div>
    </div>
    <div>
      <v-data-table
        dense
        :headers="headerTable"
        :items="dadosAgenda"
        hide-default-footer
        :search="search"
        class="elevation-1 tbLeads"
        no-data-text="Sem dados..."
        fixed-header
        height="72vh"
        :items-per-page="dadosAgenda.length"
      >
        <template v-slot:[`item.STATUS_AGENDA`]="{ item }">
          <v-chip
            class="ma-2"
            x-small
            color="blue darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'AGENDADO'"
          >
            <span class="font-weight-bold">{{ item.STATUS_AGENDA }}</span>
          </v-chip>

          <v-chip
            class="ma-2"
            x-small
            color="green darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'COMPARECEU'"
          >
            <span class="font-weight-bold">{{ item.STATUS_AGENDA }}</span>
          </v-chip>

          <v-chip
            class="ma-2"
            x-small
            color="red darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'NÃO COMPARECEU'"
          >
            <span class="font-weight-bold">{{ item.STATUS_AGENDA }}</span>
          </v-chip>

          <v-chip
            class="ma-2"
            x-small
            color="grey darken-2"
            dark
            v-if="item.STATUS_AGENDA === 'AGENDA BLOQUEADA'"
          >
            <span class="font-weight-bold">{{ item.STATUS_AGENDA }}</span>
          </v-chip>
        </template>
        <template v-slot:top>
          <v-row class="d-flex align-center">
            <v-tooltip bottom>
              <v-spacer></v-spacer>
              <template v-slot:activator="{ on, attrs }">
                <vue-excel-xlsx
                  :data="itemsRelatExcel"
                  :columns="headersRelatExcel"
                  filename="Agenda Leads"
                  :sheetname="``"
                  class="ml-5 mb-5 tbExcel"
                >
                  <v-btn
                    :disabled="dadosAgenda.length <= 0"
                    icon
                    color="blue darken-2"
                    class="mr-5 mt-5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </vue-excel-xlsx>
              </template>
              <span>Exportar para excel</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { add, sub } from "date-fns";
export default {
  name: "RelLeadsAgenda",
  computed: {},
  data: () => ({
    nomeRel: "Relatório de agendas de Leads",
    trimestre: null,
    anos: [],
    anoEscolhido: null,
    totalAgendados: 0,
    totalCompareceu: 0,
    totalNCompareceu: 0,
    totalBloqueados: 0,
    totalGeral: 0,
    dialogAguarde: false,
    dadosAgenda: [],
    headerTable: [],
    search: null,
    itemsRelatExcel: [],
    headersRelatExcel: [],
  }),
  methods: {
    async buscaAnos() {
      const anosSub = sub(new Date(), { years: 2 });

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anosSub, { years: cont }).getFullYear() <=
          new Date().getFullYear()
        ) {
          this.anos.push(add(anosSub, { years: cont }).getFullYear());
        }
      }

      this.anos = this.anos.reverse();
    },
    async buscaDados() {
      if (!this.anoEscolhido) {
        this.$toast.error("Escolha o ano!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      this.dadosAgenda = [];
      this.itemsRelatExcel = [];
      this.dialogAguarde = true;
      this.totalAgendados = 0;
      this.totalCompareceu = 0;
      this.totalNCompareceu = 0;
      this.totalBloqueados = 0;
      this.totalGeral = 0;

      const trimestreAno = this.trimestre.split("-");

      const inicioTrimeste = `${this.anoEscolhido}${trimestreAno[0]}`;
      const fimTrimeste = `${this.anoEscolhido}${trimestreAno[1]}`;

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let dados = {
        FIELDS: ` 
                  ISNULL(M0_FILIAL,'') FILIAL,
                  ZZL_ID ID_AGENDA,
                  ZZL_TITULO TITULO,
                  ZZK_COD+'-'+ZZK_NOME FONO,
                  CONVERT(VARCHAR(10),CONVERT(DATE,ZZL_DTINI, 103),103) DATA_AGENDA,
                  ZZL_HRINI+' às '+ZZL_HRFIN PERIODO,
                  CASE WHEN ZZL_STATUS = 'N' THEN 'NÃO COMPARECEU'
                      WHEN ZZL_STATUS = 'C' THEN 'COMPARECEU'
                      WHEN ZZL_STATUS = 'S' THEN 'AGENDA BLOQUEADA'
                      WHEN ZZL_STATUS = 'A' THEN 'AGENDADO' END STATUS_AGENDA,
                  ZZL_IDLEAD ID_LEAD,
                  ZZQ_NOME LEAD,
                  ZZQ_EMAIL EMAIL,
                  ZZQ_TEL TELEFONE`,
        WHERE: `ZZL_DTINI BETWEEN '${inicioTrimeste}' AND '${fimTrimeste}' 
                AND ZZL_CANAL='000002'
                AND UPPER(ZZL_TITULO) LIKE '%PACIENTES PRIMEIRA%'
                ORDER BY ZZL_DTINI `,
        INNER: `INNER JOIN ZZK010 ZZK WITH(NOLOCK) ON ZZK.D_E_L_E_T_=' ' AND ZZK_COD=ZZL_PESSOA

                INNER JOIN ZZQ010 ZZQ WITH(NOLOCK) ON ZZQ.D_E_L_E_T_=' ' AND ZZQ.D_E_L_E_T_=' ' AND ZZQ_ID=ZZL_IDLEAD

                LEFT JOIN SA3010 SA3 WITH(NOLOCK) ON SA3.D_E_L_E_T_=' ' AND A3_COD=ZZL_PESSOA

                LEFT JOIN SYS_COMPANY SYSCOP WITH(NOLOCK) ON SYSCOP.D_E_L_E_T_=' ' AND M0_CODFIL=A3_FILREF `,
        TABELA: "ZZL",
      };

      const dadosAgendas = await axios.post(url, dados);

      if (dadosAgendas.data.length > 0) {
        this.headerTable = Object.keys(dadosAgendas.data[0]).map(e => {
          return {
            text: e,
            value: e,
          };
        });
        this.headersRelatExcel = Object.keys(dadosAgendas.data[0]).map(e => {
          return {
            label: e,
            field: e,
          };
        });

        this.totalGeral = dadosAgendas.data.length;
        this.totalAgendados = dadosAgendas.data.filter(
          e => e.STATUS_AGENDA === "AGENDADO",
        ).length;
        this.totalNCompareceu = dadosAgendas.data.filter(
          e => e.STATUS_AGENDA === "NÃO COMPARECEU",
        ).length;
        this.totalCompareceu = dadosAgendas.data.filter(
          e => e.STATUS_AGENDA === "COMPARECEU",
        ).length;
        this.totalBloqueados = dadosAgendas.data.filter(
          e => e.STATUS_AGENDA === "AGENDA BLOQUEADA",
        ).length;

        this.dadosAgenda = dadosAgendas.data;
        this.itemsRelatExcel = dadosAgendas.data;
      }
      this.dialogAguarde = false;
    },
  },
  mounted() {
    this.buscaAnos();

    // this.buscaVendedores();
  },
};
</script>
<style></style>
